import { useState, useEffect, useMemo } from "react";
import { FormItem } from ".";
import { Card, Form, Select } from "antd";
import { useSelector } from "react-redux";
import { addressJson } from "../../../../constants/";
const AddressItem = ({ form }) => {
  const expertDetail = useSelector((state) => state.newExpert.expertDetail);
  const addressJsonState = useMemo(() => addressJson, []);
  const formFields = form.getFieldsValue();
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [street, setStreet] = useState([]);
  const addressFormItems = [
    {
      label: "Adres",
      rules: [],
      type: "text",
      name: "address",
      component: "textarea",
      defaultValue: expertDetail?.address?.address,
    },
    {
      label: "Adres Tarif",
      rules: [],
      type: "text",
      name: "addressDirection",
      defaultValue: expertDetail?.address?.addressDirection,
    },
  ];
  const setAllAddress = {
    city: () => {
      setCity(
        addressJsonState.map((item) => ({
          value: item.name,
          label: item.name,
        }))
      );
    },
    district: (value) => {
      setDistrict(
        addressJsonState
          .find((item) => item.name === value)
          ?.counties?.map((item) => ({
            value: item.name,
            label: item.name,
          }))
      );
    },
    street: (value) => {
      setStreet(
        addressJsonState
          .find((item) => item.name === formFields.city)
          ?.counties?.find((item) => item.name == value)
          .districts.map((item) => ({
            value: item.name,
            label: item.name,
          }))
      );
    },
  };
  useEffect(() => {
    setAllAddress.city();
    setAllAddress.district(expertDetail?.address?.city);
    setAllAddress.street(expertDetail?.address?.district);
  }, []);
  return (
    <Card className="mb-3" title="Adres Detay">
      <div className="row">
        <Form.Item
          label="Saat Dilimi"
          name={"defaultTimezone"}
          className="col-12 col-md-6 col-lg-3"
          rules={[
            {
              required: true,
              message: "Lütfen Saat Dilimi alanını doldurun.",
            },
          ]}
          initialValue={expertDetail?.defaultTimezone}
        >
          <Select
            showSearch
            optionFilterProp="value"
            name={"defaultTimezone"}
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Saat Dilimi Seçiniz"
            options={[
              { value: "Europe/Istanbul", label: "Europe/Istanbul" },
              { value: "Asia/Baku", label: "Asia/Baku" },
              { value: "Europe/London", label: "Europe/London" },
            ]}
            size="large"
          />
        </Form.Item>
      </div>
      <div className="row">
        {" "}
        <FormItem
          formItem={{
            label: "Şehir",
            rules: [],
            type: "text",
            name: "city",
            defaultValue: expertDetail?.address?.city,
          }}
        />
        <FormItem
          formItem={{
            label: "Semt",
            rules: [],
            type: "text",
            name: "district",
            defaultValue: expertDetail?.address?.district,
          }}
        />
        <FormItem
          formItem={{
            label: "Sokak",
            rules: [],
            type: "text",
            name: "street",
            defaultValue: expertDetail?.address?.street,
          }}
        />
        {/* <Form.Item
          label="Şehir"
          name={"city"}
          className="col-12 col-md-6 col-lg-3"
          initialValue={expertDetail?.address?.city}
        >
          <Select
            showSearch
            optionFilterProp="value"
            name={"city"}
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Şehir Seçiniz"
            options={city}
            size="large"
            onChange={setAllAddress.district}
          />
        </Form.Item>
        <Form.Item
          initialValue={expertDetail?.address?.district}
          label="Semt"
          name={"district"}
          className="col-12 col-md-6 col-lg-3"
        >
          <Select
            showSearch
            optionFilterProp="value"
            name={"district"}
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Semt Seçiniz"
            options={district}
            size="large"
            onChange={setAllAddress.street}
          />
        </Form.Item>
        <Form.Item
          initialValue={expertDetail?.address?.street}
          label="Sokak"
          name={"street"}
          className="col-12 col-md-6 col-lg-3"
        >
          <Select
            showSearch
            optionFilterProp="value"
            name={"street"}
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Sokak Seçiniz"
            options={street}
            size="large"
          />
        </Form.Item> */}
        <FormItem formItem={addressFormItems[0]} />
        <FormItem formItem={addressFormItems[1]} />
      </div>
    </Card>
  );
};
export default AddressItem;
