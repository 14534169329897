import React, { useEffect, useState } from "react";
import { Card, Col, Row, Statistic, Form, DatePicker, message } from "antd";
import { DashboardService } from "../../api";

import useQuery from "../hooks/useQuery";
import dayjs from "dayjs";

const CardItem = (props) => {
  return (
    <Col className={`col-lg-4 col-md-6 mb-3 ${props?.className}`}>
      <Card>
        <Statistic {...props} />
      </Card>
    </Col>
  );
};
const Dashboard = () => {
  const defaultDays = [
    dayjs().startOf("day"),
    dayjs().add(1, "day").startOf("day"),
  ];
  const [queryLoading, setQueryLoading] = useState(true);
  const [queryData, setQueryData] = useState({});
  const [forms] = Form.useForm();

  const toDateBody = (dates) => {
    return {
      startDate: dayjs(dates[0]).format("YYYY-MM-DD"),
      endDate: dayjs(dates[1]).format("YYYY-MM-DD"),
    };
  };
  const getDashboard = async (dates) => {
    try {
      const response = await DashboardService.getDashboards(dates);
      setQueryData(response.data.data);
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setQueryLoading(false);
    }
  };
  const onFinishDateForm = (values) => {
    console.log(toDateBody(values));
    getDashboard(toDateBody(values));
  };
  useEffect(() => {
    getDashboard(toDateBody(defaultDays));
  }, []);
  return (
    <Card loading={queryLoading}>
      <Form
        scrollToFirstError
        autoComplete="off"
        forms={forms}
        onFinish={onFinishDateForm}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Form.Item className="mb-0" label="Tarih" name="dates">
          <DatePicker.RangePicker
            onChange={onFinishDateForm}
            defaultValue={defaultDays}
            name={["startDate", "endDate"]}
            placeholder={["Başlangıç", "Bitiş"]}
            format={"DD-MM-YYYY "}
            showTime
          />
        </Form.Item>
      </Form>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <DatePicker.RangePicker
          name={["startDate", "endDate"]}
          placeholder={["Başlangıç", "Bitiş"]}
          format={"DD-MM-YYYY"}
          showTime
          onChange={onChangePicker}
        />
      </div> */}
      <Row>
        <CardItem
          title="Toplam Ödenen Tutar"
          value={queryData?.totalPaidAmount?.price}
          suffix="₺"
          className="col-lg-6"
        />{" "}
        <CardItem
          title="Ödeme Komisyonları Hariç Toplam Ödenen Tutar"
          value={queryData?.totalPaidAmountWithoutPaymentCommissions?.price}
          suffix="₺"
          className="col-lg-6"
        />{" "}
        <CardItem
          title="Toplam Uzman Kazanç Tutarı"
          value={queryData?.totalExpertEarningAmount?.price}
          suffix="₺"
          className="col-lg-6"
        />
        <CardItem
          title="Toplam Kâr Tutarı"
          value={queryData?.totalProfitAmount?.price}
          suffix="₺"
          className="col-lg-6"
        />
      </Row>
      <Row>
        <CardItem
          className="col-sm-6"
          title="Danışan Sayısı"
          value={queryData?.userCount}
        />

        <CardItem
          title="Uzman Sayısı"
          className="col-sm-6"
          value={queryData?.expertCount}
        />
        <CardItem
          title="Seans Girişimi"
          className="col-sm-6"
          value={queryData?.createdSessionCount}
        />
        <CardItem
          title="Planlanan Seans"
          className="col-sm-6"
          value={queryData?.plannedSessionCount}
        />
        <CardItem
          title="Tamamlanan Seans"
          className="col-sm-6"
          value={queryData?.completedSessionCount}
        />
        <CardItem
          title="Toplu Paket Satışı"
          className="col-sm-6"
          value={queryData?.totalCreditSoldCount}
        />
        <CardItem
          className="col-sm-6"
          title="Abone Sayısı"
          value={queryData?.activeSubscriptionCount}
        />
      </Row>
    </Card>
  );
};
export default Dashboard;
