import React, { useState } from "react";
import { Affix, Form, Button, message, Card, Switch, Select } from "antd";
import { CategoryService, ExpertService } from "../../../../api";
import useQuery from "../../../hooks/useQuery";
import logo from "../../../../images/logo.png";
import { categoriesListColumn } from "../../Columns";
export default function ContentSettings({ contentLanguage }) {
  const experts = useQuery({
    service: ExpertService.getExpert,
    params: {},
  });
  const category = useQuery({
    service: CategoryService.getCategories,
    params: {},
  });

  const expertList = () => {
    let data = experts.queryData?.experts?.map((item) => ({
      value: item.expertId,
      label: (
        <div className="d-flex align-items-center">
          <img
            className="mr-2"
            src={item.profilePictureUrl}
            alt=""
            width={30}
          />
          {item.fullName}
        </div>
      ),
      searchExpert: item.fullName,
    }));
    if (!data) return [];
    data = [
      {
        value: "HEALMEUP",
        label: (
          <div className="d-flex align-items-center">
            <img className="mr-2" src={logo} alt="" width={30} />
            HEALMEUP{" "}
            <small className="text-green"> (HealMeUp yöneticisi)</small>
          </div>
        ),
        searchExpert: "Heal Me Up",
      },
      ...data,
    ];
    return data;
  };
  const categoryList = () => {
    if (!category.queryData?.contentCategories) return [];

    return category.queryData?.contentCategories?.map((item) => ({
      value: item.categoryId,
      label: (
        <div className="d-flex align-items-center">
          <div
            style={{
              display: "flex",
              height: 15,
            }}
          >
            <div
              style={{
                width: 15,
                background: item.primaryColorCode,
                borderRadius: 5,
                marginRight: 1,
              }}
            ></div>
            <div
              style={{
                width: 15,
                background: item.secondaryColorCode,

                borderRadius: 5,
                marginRight: 1,
              }}
            ></div>
            <div
              style={{
                width: 15,
                background: item.tertiaryColorCode,

                borderRadius: 5,
                marginRight: 2,
              }}
            ></div>
          </div>
          {item.valueTuple[contentLanguage]}
        </div>
      ),
      searchExpert: item.valueTuple[contentLanguage],
    }));
  };
  return (
    <Card className="mb-3 " title="İçerik Ayarları">
      <div className="row">
        <Form.Item
          label="İçerik Sahibi"
          className="col-12 col-md-6 col-lg-4"
          name={"contentOwnerId"}
          rules={[
            {
              required: true,
              message: "Lütfen İçerik Sahibi alanını doldurun.",
            },
          ]}
        >
          <Select
            optionFilterProp="searchExpert"
            disabled={experts.queryLoading}
            loading={experts.queryLoading}
            showSearch
            allowClear
            name={"contentOwnerId"}
            placeholder="İçerik Sahibini Seçiniz"
            options={expertList()}
            size="large"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </div>
      <div className="row">
        <Form.Item
          label="Kategori"
          className="col-12 col-md-6 col-lg-4"
          name={"categoryId"}
          rules={[
            {
              required: true,
              message: "Lütfen Kategori alanını doldurun.",
            },
          ]}
        >
          <Select
            optionFilterProp="searchExpert"
            disabled={category.queryLoading}
            loading={category.queryLoading}
            showSearch
            allowClear
            name={"categoryId"}
            placeholder="Kategori Seçiniz"
            options={categoryList()}
            size="large"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item
          label="Kategori Tags"
          className="col-12 col-md-6 col-lg-4"
          name={"categoryTags"}
          rules={[
            {
              required: true,
              message: "Lütfen Kategori Tags doldurun.",
            },
          ]}
        >
          <Select
            mode="tags"
            name={"categoryTags"}
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Lütfen Kategori Tag Yazınız."
            size="large"
          />
        </Form.Item>
      </div>
      <div className="row">
        <Form.Item
          label="Ücretsiz İçerik"
          name={"isFree"}
          valuePropName="checked"
          className="col-12 col-md-6 col-lg-2"
          initialValue={false}
        >
          <Switch
            defaultValue={false}
            value={false}
            size="large"
            name={"isFree"}
          />
        </Form.Item>
        <Form.Item
          label="Kullanıcı Önerisi"
          name={"featured"}
          valuePropName="checked"
          className="col-12 col-md-6 col-lg-2"
          initialValue={false}
        >
          <Switch
            defaultValue={false}
            value={false}
            size="large"
            name={"featured"}
          />
        </Form.Item>

        <Form.Item
          label="İçerik Yayını"
          name={"contentStatus"}
          valuePropName="checked"
          className="col-12 col-md-6 col-lg-2"
          initialValue={false}
        >
          <Switch
            defaultValue={false}
            value={false}
            size="large"
            name={"contentStatus"}
          />
        </Form.Item>
      </div>
    </Card>
  );
}
